export default function IconVisible() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 10 10"
      id="View--Streamline-Micro"
      width="100%"
      height="100%"
    >
      <desc>{'View Streamline Icon: https://streamlinehq.com'}</desc>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5 6.25a1.25 1.25 0 1 0 0 -2.5 1.25 1.25 0 0 0 0 2.5Z"
        strokeWidth={1}
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5 1.5C2 1.5 0.5 5 0.5 5S2 8.5 5 8.5 9.5 5 9.5 5 8 1.5 5 1.5Z"
        strokeWidth={1}
      />
    </svg>
  );
}
